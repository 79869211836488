import jquery from 'jquery';
window.jQuery = window.$ = jquery;

// bootbox
import bootbox from 'bootbox';
window.bootbox = bootbox;

function trigger(e, o, c) {
  $(document).off(e, o);
  $(document).on(e, o, c);
}

$(function () {
  $(document.body).prepend(
    '<div id="page-loading-overlay"><div class="loader"></div></div>'
  );
  $('.provincia').each(function (i, obj) {
    load_province($(this));
  });
});

trigger('click', '#btn-send', function (e) {
  submit_data();
});

function submit_data() {
  var error = '',
    form_error = 'Errore di compilazione';

  var contatto_error = '';

  var nome = $.trim($('#frm_contact #nome').val());
  var cognome = $.trim($('#frm_contact #cognome').val());
  var indirizzo = $.trim($('#frm_contact #indirizzo').val());
  var provincia = $.trim($('#frm_contact #provincia option:selected').val());
  var cap = $.trim($('#frm_contact #cap').val());
  var localita = $.trim($('#frm_contact #localita').val());
  var email = $.trim($('#frm_contact #email').val());
  var telefono = $.trim($('#frm_contact #telefono').val());

  if (nome == '') contatto_error += 'Nome obbligatorio<br>';
  if (cognome == '') contatto_error += 'Cognome obbligatorio<br>';  
  //if (indirizzo == '') contatto_error += 'Indirizzo obbligatorio<br>';
  if (provincia == '') contatto_error += 'Provincia obbligatorio<br>';
  //if (cap == '') contatto_error += 'Cap obbligatorio<br>';
  if (localita == '') contatto_error += 'Località obbligatorio<br>';
  if (email != '' && !isEmail(email)) contatto_error += 'Email verifica indirizzo inserito<br>';
  if (telefono == '') contatto_error += 'Telefono obbligatorio<br>';
  if (contatto_error != '') error += '<h5 class="mt-2">Dettagli di Contatto</h5>' + contatto_error;

  var consensi_error = '';
  
  var privacy = $('#frm_contact #privacy').is(':checked') ? true : false;
   
  if (!privacy)
    consensi_error += 'Consenso Informativa Privacy obbligatorio<br>';
  if (consensi_error != '')
    error += '<h5 class="mt-2">Consensi</h5>' + consensi_error;

  if (error == '') {
    add_overlay();

    var params = {
      nome: nome,
      cognome: cognome,
      indirizzo: indirizzo,
      provincia: provincia,
      cap: cap,
      localita: localita,
      email: email,
      telefono: telefono,
      privacy: privacy ? 1 : 0,
    };

    $.ajax({
      type: 'POST',
      cache: false,
      dataType: 'json',
      url:
        './contatto/?execute=send' + '&q=' + Math.round(new Date().getTime()),
      data: params,
      success: function (data) {
        if (data.result) {
          
          _iub.cons_instructions.push([
            'submit',
            {
              form: {
                selector: document.getElementById('frm_contact'),
                map: {
                  subject: {
                    first_name: 'nome',
                    last_name: 'cognome',
                    email: 'email',
                  },
                  preferences: {
                    privacy: 'privacy',
                  },
                },
              },
              consent: {
                legal_notices: [
                  {
                    identifier: 'privacy_policy',
                  },
                  {
                    identifier: 'cookie_policy',
                  },
                  {
                    identifier: 'terms',
                  },
                ],
              },
            },
            {
              success: function (response) {
                console.log('success', response);
              },
              error: function (response) {
                console.log('error', response);
              },
            },
          ]);
          
          $('#frm_contact input:not([type=hidden])').val('');
          $('#frm_contact .provincia').prop('selectedIndex', 0).val();
          $('#frm_contact input[type=checkbox]').prop(
            'checked',
            false
          );
          message =
            '<p>Invio completato correttamente.</p>' +
            '<p>Sarete ricontattati da un nostro incaricato non appena possibile</p>';
        } else {
          message =
            '<p>Errore in fase di invio messaggio.</p>' +
            '<p>Si prega riprovare più tardi</p>' +
            '<p>Qualora il problema dovesse persistere vi preghiamo contattatare direttamente <a href="mailto:info@beautyitalianbusiness.it">info@beautyitalianbusiness.it</a></p>';
        }

        bb_alert('Invio Messaggio', message, 'Chiudi');

        remove_overlay();
      },
      error: function (xhr, ajaxOptions, thrownError) {
        remove_overlay();
        bb_alert(
          form_error,
          xhr.status + ' - ' + thrownError,
          'Chiudi e riprova più tardi'
        );
      },
      async: true,
    });
  } else {
    bb_alert(form_error, error, 'Chiudi e Verifica');
  }
}

function bb_alert(title, message, label) {
  bootbox.alert({
    title: title,
    message: message,
    centerVertical: true,
    buttons: {
      ok: {
        label: label,
        className: 'btn-primary',
      },
    },
  });
  return false;
}

function isEmail(emailStr) {
  var x = emailStr.toLowerCase();
  var filter =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (filter.test(x)) return true;
  else return false;
}

function load_province(obj) {
  $.getJSON('./contatto/data/province.json', function (data) {
    obj.not(':first-child').remove();
    $.each(data, function (i, val) {
      obj.append(
        $('<option>', {
          value: val.code,
          text: val.name,
        })
      );
    });
  }).fail(function () {
    console.log('An error has occurred.');
  });
}

function add_overlay() {
  $body = $('body');
  $body.addClass('page-loading-overlay');
  return false;
}

function remove_overlay() {
  $body = $('body');
  $body.removeClass('page-loading-overlay');
  return false;
}

/*
 const loadScript = (FILE_URL, async = true, type = 'text/javascript') => {
    return new Promise((resolve, reject) => {
      try {
        const scriptEle = document.createElement('script');
        scriptEle.type = type;
        scriptEle.async = async;
        scriptEle.src = FILE_URL;
  
        scriptEle.addEventListener('load', (ev) => {
          resolve({ status: true });
        });
  
        scriptEle.addEventListener('error', (ev) => {
          reject({
            status: false,
            message: `Failed to load the script ＄{FILE_URL}`,
          });
        });
  
        document.body.appendChild(scriptEle);
      } catch (error) {
        reject(error);
      }
    });
  };
  
  loadScript('https://www.google.com/recaptcha/api.js?hl=it&render=' + window.__RECAPTCHA__ )
    .then((data) => {
      console.log('Script loaded successfully', data);
    })
    .catch((err) => {
      console.error(err);
    });
    */
