import jquery from "jquery";
window.jQuery = window.$ = jquery;

// Bootstrap Framework
import "@popperjs/core";
import "bootstrap";

import "slick-carousel";
import hcSticky from "hc-sticky";
import AfterViewportJs from "@lysla/afterviewportjs";
import moment from "moment";

import '../custom/contact.js';
import '../custom/iubenda.js';

//#region bootstrap navbar + hamburger + body scroll lock
var navbarMain = document.getElementById("navbarMain");
navbarMain.addEventListener("show.bs.collapse", function () {
  $(".navbar-toggler .hamburger").addClass("is-active");
  let scrollableNav = document.querySelector("#navbarMain");
});
navbarMain.addEventListener("hidden.bs.collapse", function () {
  $(".navbar-toggler .hamburger").removeClass("is-active");
  let scrollableNav = document.querySelector("#navbarMain");
});
//#endregion

//#region stickybar
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".stickybar", {
    stickTo: "body",
    stickyClass: "stickybar--sticked",
  });
});
//#endregion
//#region offcavas menu mobile
$("[data-trigger]").on("click", function () {
  var trigger_id = $(this).attr("data-trigger");
  $(trigger_id).toggleClass("show");
  $("body").toggleClass("offcanvas-active");
});
$(".close-icon").on("click", function (e) {
  $("#navbarMain").removeClass("show");
  $("body").removeClass("offcanvas-active");
});
//#endregion

//#region class on active nav link
document.addEventListener("DOMContentLoaded", function () {
  const links = document.querySelectorAll(".nav-item .nav-link");

  links.forEach((link) => {
    let slug = link.getAttribute("href").split("/").reverse()[0];
    if (window.location.href.indexOf(slug) > -1) {
      link.classList.add("active");
    }

    if (window.location.href === link.getAttribute("href")) {
      link.classList.add("active");
    }
  });
});

//#endregion
//#region promo countdown
$(function () {
  $(".details__countdown").each(function () {
    let $cdDays = $(this).find("> div:nth-child(1) > span:first-child");
    let $cdHours = $(this).find("> div:nth-child(2) > span:first-child");
    let $cdMinutes = $(this).find("> div:nth-child(3) > span:first-child");
    let $cdSeconds = $(this).find("> div:nth-child(4) > span:first-child");
    let cdEnd = $(this).data("countdown-end");
    cdEnd = moment(cdEnd);
    let cdNow = moment();
    let cdDuration = moment.duration(cdEnd.diff(cdNow));
    if (cdDuration.as("milliseconds") > 0) {
      let cdInterval = setInterval(() => {
        if (cdDuration.as("milliseconds") > 0) {
          cdDuration.subtract(1, "second");
          $cdSeconds.text(cdDuration.get("seconds"));
          $cdMinutes.text(cdDuration.get("minutes"));
          $cdHours.text(cdDuration.get("hours"));
          $cdDays.text(cdDuration.get("days"));
        } else {
          clearInterval(cdInterval);
          window.location.reload();
        }
      }, 1000);
    }
  });
});
//#endregion
